import { evaluateMathOperation } from '../../lib/utils';
import MatrixLookup from './MatrixLookup';

/// evaluate given condition records
const EvaluateCondition = (conditionRecords, answers, matrixData, configModel) => {
    
    answers["IV_CONFIG_GRP"] = configModel.replace("PG","CG");
    answers["BUSINESS_UNIT_IN"] = answers["BUSINESS_UNIT_IN"].replace("002-","");
    // Note: This component, being called conditionally, should not use hooks
    // like useState and useContext etc. to maintain react hooks order. 
    if (conditionRecords === null || conditionRecords === undefined || conditionRecords.length === 0)
    {
        
        return false;
        //throw new Error('No conditions given for evaluation');
    }
        

    
    const condOperator = conditionRecords[0].conditionOperator;
  
    if (
        condOperator === 'OR' || condOperator === '' ||
        !condOperator // blank or null or undefined
    ) {
  
        let result = false;
        for(let i=0;i< conditionRecords.length; i++){
            let { conditionVariable, conditionAnswer, conditionCompOperator } = conditionRecords[i];
            const leftOperand =
            conditionRecords[i].matrix === true
                    ? MatrixLookup(conditionVariable, answers, matrixData)
                    : (conditionVariable === "1" ? "1" : answers[conditionVariable]);

            const rightOperand = (conditionAnswer === "1" ? "1" : conditionAnswer);
            if (
                leftOperand && 
                evaluateMathOperation(
                    leftOperand,
                    rightOperand,
                    conditionCompOperator
                )
            ) {
                result = true;
                break;
            }
        }
        return result;
    } else if (condOperator === 'AND') {
        let result = true;
        for(let i=0;i< conditionRecords.length; i++){
            let { conditionVariable, conditionAnswer, conditionCompOperator } = conditionRecords[i];
            const leftOperand =
            conditionRecords[i].matrix === true
                    ? MatrixLookup(conditionVariable, answers, matrixData)
                    : answers[conditionVariable];
            const rightOperand = conditionAnswer;

            if (
                evaluateMathOperation(
                    leftOperand,
                    rightOperand,
                    conditionCompOperator
                ) === false
            ) {
                result = false;
                break;
            }
        }
        return result;
    } else return true;
};

export default EvaluateCondition;
