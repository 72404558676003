import MatrixLookup from './MatrixLookup';
import { evaluateMathOperation } from '../../lib/utils';
import { isEmpty } from '../../lib/utils';
import EvaluateCondition from './EvaluateCondition';

// calculate product price based on user selections

function getPrice(pricing, product){
    let price = 0;
 
    if(pricing.length > 0)
    {
        var pf = pricing.split(",");
        if(pf[0] === "1")
            price = product.msrp;
        if(pf[1] === "1")
            price = product.wholeSalePrice; 
        if(pf[2] === "1")
            price = product.basePrice;
    }
    return price;
}

function formulaConditions(priceFormula)
{
    let dependentConditions = {};
 
    for (let pf of priceFormula) {
        // populate conditions object with condition used in the question
        if (!isEmpty(pf.condition)) {
            dependentConditions[pf.condition] = pf.conditions;
        }
    }
    return dependentConditions;
}

function evaluateFormulaCondition(formulaConditions, answers, matrixData, configModel)
{
   
    const evaluationResult = {};
    for (const [conditionName, conditions] of Object.entries(formulaConditions)) {
        
        evaluationResult[conditionName] = EvaluateCondition(
            conditions,
            answers,
            matrixData,
            configModel
        );
    }
    
    return evaluationResult;
    
}

function configKitBasePriceOverride(varName, existValue){
    if(varName === "basePrice" )
        return 0;
    return existValue;

}

 
 
function sortFormula(priceFormula)
{
    return priceFormula.sort((a, b) => {
        // Check if variable1 includes "PRICE"
        const aVariable1IncludesPrice = a.variable1.toUpperCase().includes('PRICE') && !a.variable1.toUpperCase().includes('BASE_PRICE') ;
        const bVariable1IncludesPrice = b.variable1.toUpperCase().includes('PRICE') && !a.variable1.toUpperCase().includes('BASE_PRICE');

 
        if (aVariable1IncludesPrice && !bVariable1IncludesPrice) {
            return -1; // a comes before b
        }
        if (!aVariable1IncludesPrice && bVariable1IncludesPrice) {
            return 1; // b comes before a
        }
        if (aVariable1IncludesPrice && bVariable1IncludesPrice) {
            return -1; // b comes before a
        }
    
        // // Check if variable2 includes "PRICE"
        // const aVariable2IncludesPrice = a.variable2.toUpperCase().includes('PRICE');
        // const bVariable2IncludesPrice = b.variable2.toUpperCase().includes('PRICE');
    
        // if (aVariable2IncludesPrice && !bVariable2IncludesPrice) {
        //     return -1; // a comes before b
        // }
        // if (!aVariable2IncludesPrice && bVariable2IncludesPrice) {
        //     return 1; // b comes before a
        // }
    
        // No specific preference, maintain original order
        return 99;
    });
}

export function calculatePrice(priceFormula, matrixData, answers, product, pricing) {
    let price = 0;
    product.basePrice = getPrice(pricing, product);
    let origPrice = product.basePrice;
    let conditions = formulaConditions(priceFormula);
 
    const conditionsEvalResult = evaluateFormulaCondition(
        conditions,
        answers,
        matrixData,
        product.configModel
    );

    let sortedPriceFormula = product["isConfigKit"] === "Y" ? sortFormula(priceFormula) : priceFormula;
    let basePriceOverriden = false;
    //evaluate individual item in the price formula and add to previous value of price
    for (let item of sortedPriceFormula) {
        //checkCondition
        if(item.condition.trim() === "" || conditionsEvalResult[item.condition])
        {
            let { variable1, variable2, operator, matrix1, matrix2 } = item;
            //if(variable1 === "MAR_RDY_PRICE" || variable2 ==="MAR_RDY_PRICE")
 
            // change variable name of 'BASE_PRICE' as the product object has property 'basePrice'
            if (variable1 === 'BASE_PRICE') variable1 = 'basePrice';
            if (variable2 === 'BASE_PRICE') variable2 = 'basePrice';
    
            // do matrix lookup as required, else get the value from product object
            let value1 =
                !isEmpty(variable1) && matrix1
                    ? MatrixLookup(variable1, answers, matrixData)
                    : product[variable1];
            value1 = Number(value1) || 0;
            debugger;
            if(variable1.toUpperCase().includes("PRICE") && value1 > 0)
            {
                basePriceOverriden = true;
                product["basePrice"] = value1;

            }
                
            //debugger;
            let value2 =
                !isEmpty(variable2) && matrix2
                    ? MatrixLookup(variable2, answers, matrixData)
                    : product[variable2] 
                    value2 = Number(value2) || 0;
 

            if(variable1 === 'MTX_PD_SPKR_PRICE')
                debugger;
             if (!isEmpty(variable1))
             {
                if(isEmpty(variable2))
                {
                    if( variable1 === 'basePrice'  && product["isConfigKit"] === "Y" )
                    {
                        if(basePriceOverriden)
                        value1 = configKitBasePriceOverride( variable1, value1);
                    }
                       
                    price = evaluateMathOperation(price, value1, operator);
                }
                else{

                     if( variable1 === 'basePrice' && product["isConfigKit"] === "Y" )
                     {
                         if(basePriceOverriden)
                            value1 = configKitBasePriceOverride(variable1, value1);
                     }

                    // if both variable1 and variable2 are present, first evaluate the
                    // expression and then add to price
                    const expressionValue = evaluateMathOperation(
                        value1,
                        value2,
                        operator
                    );
                    price = evaluateMathOperation(price, expressionValue, '+'); // '+' is hardcoded here
                    }
              
               
             }
                
             
         
            else
            {
                price = evaluateMathOperation(price, 0, '+');
            }
            
        }  
        // else
        // {
        //     price = evaluateMathOperation(price, 0, '+');
        // }
    }

    if(price <= 0)
        return origPrice;
     else if(price < product["basePrice"])
          return price + origPrice;
    

    return price;
}
