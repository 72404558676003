import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import Spinner from '../../common/Spinner';
import { toast } from 'react-toastify';

const ProductsDropdown = ({ category, onSelect, selectedProduct, isReadOnly, customerNumber,warehouse }) => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // fetch products
        const getProducts = async () => {
            try {
                 if(warehouse === null || warehouse === "null")
                     warehouse=  "GMMFXfiP2bOewy4ruB/AsQ==";
                setIsLoading(true);
                const response = await fetch(
                    process.env.REACT_APP_SERVER_URL +
                        `Product/GetProductsByCategory?c=${customerNumber}&cat=${category.categoryId}&wh=${warehouse}`
                );
                const productsData = await response.json();
                setProducts(productsData);
            } catch (error) {
                //console.log('error fetching products', error);
                toast.error('Error getting Product list.');
            } finally {
                setIsLoading(false);
            }
        };
        getProducts();
    }, [category.categoryId]);

    const handleProductChange = (event) => {
        const selectedProduct = products.filter(
            (x) => x.sku === event.target.value
        )[0];
        onSelect(selectedProduct);
    };

    return (
        <>
            {isLoading && <Spinner />}
            <Label for='product'>Product</Label>
            <select
                className='custom-select basic-single category'
                id='product'
                name='product'
                onChange={handleProductChange}
                value={selectedProduct ? selectedProduct.sku : ''}
                disabled={isReadOnly}
            >
                <option value=''>Select an option</option>
                {products.map((x) => (
                    <option key={x.productId} value={x.sku}>
                        {x.description}
                    </option>
                ))}
            </select>
        </>
    );
};

export default ProductsDropdown;
