import React, { useEffect, useState } from 'react';
import { isEmpty } from '../../lib/utils';

const AnswerChoices = ({
    product,
    questionVariable,
    choices,
    isDropdown,
    defaultValue,
    conditionEvaluations,
    onAnswerGiven,
}) => {
    const [filteredChoices, setFilteredChoices] = useState([]);
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    useEffect(() => {
        // If there's exactly one choice and no selection yet, auto-select it
        const validChoices = choices.filter(choice => 
            isEmpty(choice.conditionName) ||
            (!isEmpty(choice.conditionName) && conditionEvaluations[choice.conditionName] === true)
        );
        if (isDropdown && validChoices.length === 1 ) {
            onAnswerGiven(validChoices[0].optionValue);
            setSelectedValue(validChoices[0].optionValue);
        }

        setFilteredChoices(validChoices);
        
    }, [choices,isDropdown, onAnswerGiven]);
    
    function handleChoiceOptionChange(event) {
        setSelectedValue(event.target.value);
        onAnswerGiven(event.target.value);
    }

    // show options dropdown or a textbox depending on available info
    if (isDropdown)
        return (
            <select
                name={`select_${product.productId}_${questionVariable}`}
                className='custom-select basic-single'
                value={selectedValue}
                onChange={(event) => handleChoiceOptionChange(event)}
            >
                <option value='<>'>Select an option</option>
                {filteredChoices.map(choice => (
                    <option
                        key={`${product.productId}_${questionVariable}_${choice.id}`}
                        value={choice.optionValue}
                    >
                        {choice.optionText}
                    </option>
                ))}
            </select>
        );
    else
        return (
            <input
                type='text'
                name={`text_${product.productId}_${questionVariable}`}
                value={selectedValue}
                className='form-control'
                placeholder='Enter your value'
                onChange={(event) => handleChoiceOptionChange(event)}
            />
        );
};

const ConsoleLog = (props) =>{
    console.log("props", props);

    return (<></>);
}
export default AnswerChoices;
