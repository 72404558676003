import React from 'react';
import { Label } from 'reactstrap';

const CategoriesDropdown = ({ onSelect, categories, selectedCategory, isReadOnly }) => {
    const handleCategoryChange = (event) => {
        const selectedCategory = categories.filter(
            (x) => x.categoryId === event.target.value
        )[0];
        onSelect(selectedCategory);
    };

    return (
        <>
            <Label for='categories'>Category</Label>
            <select
                className='custom-select basic-single category'
                id='categories'
                name='categories'
                onChange={handleCategoryChange}
                value={selectedCategory ? selectedCategory.categoryId : ''}
                disabled={isReadOnly}
            >
                <option value='' disabled>
                    Select a Product Category
                </option>
                {categories &&
                    categories.map((x) => (
                        <option key={x.categoryId} value={x.categoryId}>
                            {x.categoryText}
                        </option>
                    ))}
            </select>
        </>
    );
};

export default CategoriesDropdown;
