import {useState} from 'react';
import { Row, Col,Tooltip } from 'reactstrap';
import AnswerChoices from './AnswerChoices';

// represents a single question
const Question = ({
    product,
    questionObj,
    selectedAnswer,
    isRequired,
    isVisible,
    conditionEvaluations,
    onAnswerGiven,
}) => {

        const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const handleAnswer = (choice) => {
        onAnswerGiven(questionObj.question.variable, choice);
    };
  
    
    const getSelectedChoice = () => {
        let selectedChoiceValue = '';
        
        if (selectedAnswer) {
            selectedChoiceValue = selectedAnswer;
        } else {
            const defaultChoice = questionObj.options.find(
                (option) => option.isDefault
            );
            if (defaultChoice) {
                selectedChoiceValue = defaultChoice.optionValue;
            }
        }
        return selectedChoiceValue;
    };
 
    return (
        isVisible && (
            <Row
                key={`${product.productId}_${questionObj.question.variable}`}
                style={{ marginTop: '20px' }}
            >
                <Col md='12' style={{ fontWeight: 'bold' }}>
                <span className={`${isRequired ? "requiredQ" : ""}`}>{questionObj.question.questionText}</span>
                    {questionObj.question.tooltip && questionObj.question.tooltip.length > 0 && (
                       <>
                       <span id={`p_${product.productId}_${questionObj.question.variable}`} className='tooltipIcon'> 
                            <i className="info">i</i>
                            </span>
                            <Tooltip
                            placement="right"
                            isOpen={tooltipOpen}
                            target={`p_${product.productId}_${questionObj.question.variable}`}
                            toggle={toggleTooltip}
                            >
                            {questionObj.question.tooltip}
                            </Tooltip>
                       </>     

                    )}
                   
              
                </Col>
                <Col md='12'>
                    <AnswerChoices
                        key={`${product.productId}_${questionObj.question.variable}`}
                        product={product}
                        questionVariable={questionObj.question.variable}
                        choices={questionObj.options}
                        isDropdown={
                            (questionObj.question.optionConstraint != null && questionObj.question.optionConstraint !== '') ||
                            (questionObj.question.options != null && questionObj.question.options !== '')
                        }
                        defaultValue={getSelectedChoice()}
                        conditionEvaluations={conditionEvaluations}
                        onAnswerGiven={handleAnswer} 
                    />
                    <ConsoleLog question={questionObj.question} conditionEvaluations={conditionEvaluations}   choices={questionObj.options} />
                </Col>
            </Row>
        )
    );
};


const ConsoleLog = (props) =>{
    console.log("props", props);

    return (<></>);
}
export default Question;
